.leftside, .rightside {
    height: 50vh;
    width: 100%;
    
}

@media screen and (min-width: 768px) {
    .landing-logo{
        top: 20% !important;
        backdrop-filter: blur(5px) !important;
        max-width: 400px;
    }
    .split-heading{
        width: 30vw;
        color:white !important;
        border: none;
        font-size: xx-large !important;
        text-shadow: none;
        backdrop-filter: blur(0px);
    }
    .split-heading:hover{
        width: 30vw;
        color:rgba(0, 0, 0, 0.5)  !important;
        background-color: rgba(255, 255, 255, 0.7) !important;
        border: none;
        font-size: xx-large !important;
        backdrop-filter: blur(10px);
        
        transition: 1s;
    }
    .leftside, .rightside{
        height: 100vh;
       
    }
    
    .content-landing-page{
        height: 100%;
        width: 100%;
        backdrop-filter: blur(0px) !important;
        transition: 2s;
        background-color: rgba(63, 116, 191, 0.2);
        border-radius: 0px !important;
    }
    
    .content-landing-page:hover{
        height: 100%;
        width: 100%;
        backdrop-filter: blur(2px) !important;
        background-color: rgba(63, 116, 191, 0.7);
        
    }
}
.leftside {
    background-size: cover;
    background-position: center;
    background-image: url("/assets/images/landing/house_placeholder.jpg");
    
    
    
}

.rightside{
    background-size: cover;
    background-position: center;
    background-image: url("/assets/images/landing/flat_placeholder.jpg");
}
.landing-logo{
    z-index: 1;
    position: absolute;
    text-align: center;
    top: 50vh;
    left: 50%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    width: 75%;
    color: white;
    border-radius: 30px;
    backdrop-filter: blur(15px);
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    
}

.split-heading{
    max-width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 15vh;
    text-align: center;
    font-size: 3vh;
    padding: 30px;
    margin-bottom: 0px;
    color:white !important;
    font-weight: 500 !important;
    border-radius: 30px !important;
    border: none;
    background-color: rgba(255, 255, 255, 0.2);
}
.split-heading:hover{
    color:rgba(0, 0, 0, 0.5)  !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    border: none;
    text-shadow: none;
}
.content-landing-page{
    backdrop-filter: blur(2px);
    border-radius: 30px;
    text-shadow: black 0px 0px 2px;
    
}
