.logo-map-background{
    position: relative;
    width: 200px !important;
    color: white;
    border-radius: 0px 30px 30px 30px;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 3px;
    background-color: rgba(255, 255, 255, 0.3);
}

.logo-map{
    width: 200px !important;
    color: white;
    
    
}
.logo-map-background:after {
    width: 12px;
    height: 12px;
    background: rgba(0, 0, 0, 0.8);
    left: -5px;
    top: -5px;
    position: absolute;
    content: "";
    border-radius: 100%;
  }