.small-margin-bottom{
    margin-bottom: 15px;
}
.medium-margin-bottom{
    margin-bottom: 30px;
}
.small-text-custom{
font-size: 1rem !important;
}
.big-margin-bottom{
    margin-bottom: 60px;
}
.cursor-pointer{
    cursor: pointer;
}
.white-btn:hover{
background-color: #fff !important;
border: none !important;
}
.feature-area-logo{
    width: 100px;
    margin-left: 5px;
    vertical-align: bottom;
}