.header-logo-white{
    min-width: 145px;
    max-height: 55px;
    cursor: pointer;
    font-size: 20px;
    color:white;
}
.header-logo-dark{
    min-width: 145px;
    max-height: 55px;
    cursor: pointer;
    font-size: 20px;
}
.header-logo-white:hover{
    font-size: 22px;
}
.header-logo-dark:hover{
    font-size: 22px;
}
.cursor-btn{
    cursor: pointer;
}
.header-logo-blue{
    min-width: 145px;
    max-height: 55px;
    cursor: pointer;
    font-size: 20px;
    color: rgb(1, 132, 240) !important; 
    
}
.header-logo-blue:hover{
    color: rgb(1, 80, 240) !important; 
}
.header-logo-blue-small{
    min-width: 145px;
    max-height: 55px;
    cursor: pointer;
    font-size: 16px;
    color: rgb(1, 132, 240) !important; 
    
}
.header-logo-blue-small:hover{
    color: rgb(1, 80, 240) !important; 
}
@media only screen and (max-width: 600px) {
    .header-logo-white{
        font-size: 15px;
    }
    .header-logo-dark{
        font-size: 15px;
    }
    .header-logo-white:hover{
        font-size: 17px;
    }
    .header-logo-dark:hover{
        font-size: 17px;
    }
}