.loading-page-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh ;
    background-color: whitesmoke;
}

.content-div{
    padding-bottom: 20vh;
}

.loading-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    min-width: 200px;
}
.spinner-border{
    width: 70px;
    height: 70px;
    border-width: 5px;
}
.contact-href{
    color: #1d1d24;
}
.contact-href:hover{
    color: #1d1d2491;
}